import { useCallback, useEffect, useState } from 'react';
import Select from '../../components/Select.jsx';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import useFetch from '../../hooks/useFetch.js';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

const OnBehalfOf = ({
  setBehalfOfFields,
  setShowFields,
  formValidate,
  demographics,
  setDemographics,
  edit
}) => {
  const { id } = useSelector((state) => state.mainFun.user);

  const [caseFor, setCaseFor] = useState('');
  const [onBehalf, setOnBehalf] = useState({ sub_profile: '', behalf_of: '' });
  const [behalfOfData, setBehalfOfData] = useState([]);
  const [relatedProfiles, setRelatedProfiles] = useState([]);

  const [searchParams] = useSearchParams();

  const { t } = useTranslation();
  const { fetchData } = useFetch({});

  const caseForTabs = [
    { value: 'me', label: t('demographics.myself') },
    { value: 'someone_else', label: t('demographics.someone_else') }
  ];

  const getSubProfiles = async () => {
    await fetchData({
      url: 'patient/profile/get',
      body: {}
    }).then((result) => {
      if (result?.result?.patient_profiles?.length > 0) {
        setRelatedProfiles(result.result.patient_profiles.map((e) => ({ value: e.id, label: e.name })));
      }
    });
  };

  const getBehalfOfData = async () => {
    await fetchData({
      url: 'patient/relations/get',
      body: {}
    }).then((result) => {
      if (result?.result?.patient_relations) {
        setBehalfOfData(result.result.patient_relations.map((e) => ({ value: e.id, label: e.name })));
      }
    });
  };

  const getDemographics = useCallback(async () => {
    const body = {};
    console.log(onBehalf);
    if (onBehalf?.sub_profile) body.sub_profile = onBehalf?.sub_profile;

    await fetchData({ url: 'patient/demographics/get', body: { params: body }, loading: true }).then(
      (result) => {
        if (result?.result?.patient?.gender) {
          setDemographics(result.result.patient);
        }
      }
    );
  }, [onBehalf, caseFor]);

  const behalfOfFields = [
    {
      title: t('patient first name'),
      placeholder: t('type', { val: 'patient first name' }),
      name: 'first_name',
      colValue: 'col-6',
      value: demographics?.first_name,
      order: 0
    },
    {
      title: t('patient last name'),
      placeholder: t('type', { val: 'patient last name' }),
      name: 'last_name',
      colValue: 'col-6',
      value: demographics?.last_name,
      order: 0
    }
  ];

  useEffect(() => {
    if (!demographics) {
      getBehalfOfData();
      getSubProfiles();
      if (id == searchParams.get('id')) {
        setCaseFor('me');
      } else if (searchParams.get('id')) {
        setCaseFor('someone_else');
        setOnBehalf((prev) => ({ ...prev, sub_profile: searchParams.get('id') }));
      } else {
        setCaseFor('');
      }
    }
  }, [demographics]);

  useEffect(() => {
    if (caseFor === 'someone_else') {
      setBehalfOfFields({
        fields: behalfOfFields,
        validateFields: [
          {
            name: 'first_name',
            validate: 'name'
          },
          {
            name: 'last_name',
            validate: 'name'
          }
        ]
      });
    }
  }, [demographics]);

  useEffect(() => {
    setDemographics({});
    if (caseFor === 'me' || (caseFor === 'someone_else' && onBehalf?.sub_profile)) {
      getDemographics();
    }
  }, [onBehalf?.sub_profile, caseFor]);

  useEffect(() => {
    if (caseFor === 'someone_else') {
      if (onBehalf?.sub_profile || (onBehalf?.behalf_of && !onBehalf?.sub_profile)) {
        setShowFields(true);
      } else {
        setShowFields(false);
      }
    } else if (caseFor === 'me') {
      setBehalfOfFields({
        fields: [],
        validateFields: []
      });
      setShowFields(true);
    }
  }, [onBehalf, caseFor]);

  const onRelationChange = (e) => {
    if (e) {
      setOnBehalf((prev) => ({ ...prev, sub_profile: e.value }));
    } else {
      setOnBehalf({});
      setDemographics({});
    }
  };

  console.log(onBehalf);

  return (
    <div className="d-flex flex-column gap-4">
      {<p>{t(edit?'demographics.edit_demo':'demographics.title')}:</p>}
      <div className="d-flex gap-3 justify-content-center">
        {caseForTabs.map((e, i) => (
          <button
            className={clsx('rounded-pill px-3 py-1 border text-capitalize primary-txt text-center', {
              'bg-dark-green text-white border-0': caseFor === e.value,
              'bg-transparent grey-border': caseFor !== e.value
            })}
            onClick={() => {
              setCaseFor(e.value);
              setOnBehalf('');
            }}
            type="button"
          >
            {e.label}
          </button>
        ))}
      </div>
      {caseFor === 'someone_else' && (
        <>
          {relatedProfiles.length > 0 && (
            <Select
              title={t('demographics.related')}
              isClearable={true}
              data={relatedProfiles}
              name="sub_profile"
              value={onBehalf?.sub_profile}
              setValue={formValidate?.setValue}
              control={formValidate?.control}
              onChange={onRelationChange}
            />
          )}
          <Select
            title={t('demographics.on_behalf')}
            name="relation_id"
            data={behalfOfData}
            control={formValidate?.control}
            setValue={formValidate?.setValue}
            value={demographics?.['relation_id']}
            onChange={(e) => setOnBehalf((prev) => ({ ...prev, behalf_of: e }))}
          />
        </>
      )}
    </div>
  );
};

export default OnBehalfOf;
