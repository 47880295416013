import { useDispatch, useSelector } from 'react-redux';
import PopupContainer from '../../Popup_Container.jsx';
import { useLayoutEffect, useState } from 'react';
import { setAlert } from '../../../features/main_functions.js';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../hooks/useFetch.js';
import poLogo from '../../../assets/images/logo.png';

function PatientNewCaseConsentPop({ setConsentAccepted, caseId }) {
  const [consentDetails, setConsentDetails] = useState();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { fetchData } = useFetch({});

  const { main_logo: entityLogo } = useSelector((store) => store.mainFun.entityDetails);

  const handleSubmit = (e, close) => {
    fetchData({
      url: 'submit/patient/consent',
      loading: true,
      body: {
        params: { consent_id: consentDetails.id, case_id: caseId }
      }
    }).then((result) => {
      if (result) {
        setConsentAccepted(true);
        close();
        dispatch(setAlert({ txt: t('alert_box.case_files.signed'), type: 'success' }));
      }
    });
  };

  const btns = [
    {
      txt: t('newCase.files.sign'),
      style: 'green-btn fw-semibold',
      type: 'submit',
      id: 'consent'
    }
  ];

  const getConsent = async () => {
    await fetchData({ url: 'patient/consent/template', loading: true, body: {} }).then((result) => {
      if (result?.result) {
        setConsentDetails(result.result);
      }
    });
  };

  useLayoutEffect(() => {
    getConsent();
  }, []);

  return (
    <PopupContainer title={t('newCase.files.sign_consent')} btns={btns} open={true} handleSubmit={handleSubmit} closeBtn={false}>
      <div className="d-flex justify-content-between">
        <img src={poLogo} height={80} />
        <img src={entityLogo} height={80} />
      </div>
      <div dangerouslySetInnerHTML={{ __html: consentDetails?.consent_body }} />
    </PopupContainer>
  );
}
export default PatientNewCaseConsentPop;
