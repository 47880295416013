import { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useFetch from '../../hooks/useFetch.js';
import { setAlert } from '../../features/main_functions.js';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

function ActivateAccount({ portalToken }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [counter, setCounter] = useState(5);
  const [activateState, setActivateState] = useState();
  const [loginTimeout, setLoginTimeout] = useState();
  const [counterInterval, setCounterInterval] = useState();
  const [email, setEmail] = useState();

  const token = searchParams.get('token');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { fetchData } = useFetch({});

  const activateAccount = async () => {
    await fetchData({
      url: 'account/activate',
      loading: true,
      body: {
        activation_token: token,
        'Portal-Token': portalToken
      }
    }).then((result) => {
      if (result?.result && !result?.result?.error) {
        if (result.result.message == 'Token expired') {
          setActivateState('expired');
          setEmail(result.result.email);
        } else {
          setActivateState('activate');
          const countInt = setInterval(() => {
            setCounter((e) => e - 1);
          }, 1000);
          const timeOut = setTimeout(() => {
            navigate('/patient/login');
          }, 5000);
          setCounterInterval(countInt);
          setLoginTimeout(timeOut);
        }
      } else if (result?.result?.error) {
        setActivateState('already');
      } else {
        setActivateState('error');
      }
    });
  };

  useLayoutEffect(() => {
    activateAccount();
  }, []);

  useEffect(() => {
    return () => {
      loginTimeout && clearTimeout(loginTimeout);
      counterInterval && clearInterval(counterInterval);
    };
  }, [counterInterval, loginTimeout]);

  const resendActivation = async () => {
    await fetchData({
      url: 'account/activate/resend',
      loading: true,
      body: { email: email, 'Portal-Token': portalToken }
    }).then((result) => {
      if (result) {
        dispatch(setAlert({ txt: t('alert_box.email_sent'), type: 'success' }));
        navigate('../login');
      }
    });
  };
  return (
    <div className="d-flex flex-column align-items-center mt-5">
      <h2>
        {activateState == 'activate' ? (
          t('onboarding.patient_activate.activate', { counter })
        ) : activateState == 'already' ? (
          t('onboarding.patient_activate.already')
        ) : activateState == 'expired' ? (
          <span>
            {t('onboarding.patient_activate.expired.0') + ', '}
            <span className="green-txt pointer" onClick={resendActivation}>
              {t('onboarding.patient_activate.expired.1')}
            </span>
          </span>
        ) : (
          activateState == 'error' && t('onboarding.patient_activate.error')
        )}
      </h2>
    </div>
  );
}

export default ActivateAccount;
