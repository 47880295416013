import React, { useCallback, useEffect, useState } from 'react';
import Form from '../../components/Form';
import { countries } from 'countries-list';
import useFetch from '../../hooks/useFetch.js';
import moment from 'moment';
import { setAlert } from '../../features/main_functions.js';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import OnBehalfOf from './on_behlaf.jsx';
import { useSearchParams } from 'react-router-dom';

const Demographics = ({ setRequestState, title }) => {
  const { id } = useSelector((state) => state.mainFun.user);

  const [demographics, setDemographics] = useState();
  const [behalfOfFields, setBehalfOfFields] = useState({
    fields: [],
    validateFields: []
  });
  const [showFields, setShowFields] = useState(false);
  const [formValidate, setFormvalidate] = useState({});

  const [searchParams, setSearchParams] = useSearchParams();

  const { fetchData } = useFetch({});
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const countryOptions = Object.entries(countries).map(([countryCode, countryData]) => ({
    value: countryData.phone[0],
    label: `${i18n.language === 'en' ? countryData.name : t(`countries.${countryCode}`)} (+${
      countryData.phone
    })`
  }));
  countryOptions.sort((a, b) => a.label.localeCompare(b.label));

  let inputWithDropdownArr = [
    {
      title: t('demographics.phone_number'),
      placeholder: t('type', { val: 'demographics.phone_number' }),
      inputName: 'mobile',
      selectName: 'country',
      colValue: 'col-12',
      data: countryOptions,
      inputValue: demographics?.mobile?.split(')')?.pop(),
      selectValue: demographics?.mobile?.split('+')?.pop()?.split(')')[0],
      order: 1
    }
  ];

  let inputArr = [
    ...behalfOfFields.fields,
    {
      title: t('demographics.date_of_birth'),
      placeholder: t('select', { val: 'demographics.date' }),
      name: 'date_of_birth',
      colValue: 'col-6',
      type: 'date',
      max: moment().format('YYYY-MM-DD'),
      value: demographics?.date_of_birth,
      order: 2
    },
    {
      title: t('demographics.weight') + ' (Kg)',
      placeholder: t('type', { val: 'demographics.weight' }),
      name: 'weight',
      colValue: 'col-6',
      type: 'number',
      value: demographics?.weight,
      order: 3
    },
    {
      title: t('demographics.height') + ' (Cm)',
      placeholder: t('type', { val: 'demographics.height' }),
      name: 'height',
      colValue: 'col-6',
      type: 'number',
      value: demographics?.height,
      order: 4
    },
    {
      title: t('demographics.city'),
      placeholder: t('type', { val: 'demographics.city' }),
      name: 'city',
      colValue: 'col-6',
      value: demographics?.city,
      order: 8
    },
    {
      title: t('demographics.zip'),
      placeholder: t('type', { val: 'demographics.zip' }),
      name: 'zip',
      colValue: 'col-6',
      value: demographics?.zip,
      order: 9
    }
  ];

  const textAreaArr = [
    {
      title: t('demographics.address'),
      placeholder: t('type', { val: 'demographics.address' }),
      name: 'address',
      colValue: 'col-12',
      value: demographics?.address,
      order: 10
    }
  ];

  const countryList = Object.entries(countries).map(([countryCode, countryData]) => ({
    value: countryCode,
    label: i18n.language === 'en' ? countryData.name : t(`countries.${countryCode}`)
  }));

  countryList.sort((a, b) => a.label.localeCompare(b.label));

  const bloodGroup = [
    { value: 'A+', label: 'A+' },
    { value: 'A-', label: 'A-' },
    { value: 'B+', label: 'B+' },
    { value: 'B-', label: 'B-' },
    { value: 'O+', label: 'O+' },
    { value: 'O-', label: 'O-' },
    { value: 'AB+', label: 'AB+' },
    { value: 'AB-', label: 'AB-' },
    { value: 'unknown_type', label: "Don't know" }
  ];

  let selectArr = [
    {
      title: t('demographics.country'),
      name: 'country_id',
      colValue: 'col-6',
      data: countryList,
      value: demographics?.country_id,
      order: 7
    },
    {
      title: t('demographics.gender.gender'),
      name: 'gender',
      colValue: 'col-6',
      data: [
        { value: 'male', label: t('demographics.gender.male') },
        { value: 'female', label: t('demographics.gender.female') }
      ],
      value: demographics?.gender,
      order: 5
    },
    {
      title: t('demographics.blood_group'),
      name: 'blood_type',
      colValue: 'col-6',
      data: bloodGroup,
      value: demographics?.blood_type,
      order: 6
    }
  ];

  let btnArr = [
    {
      title: title ? t('next') : demographics?.gender ? t('edit') : t('add'),
      style: 'green-btn',
      type: 'submit',
      order: 11
    }
  ];

  const onSubmitHandler = useCallback(
    async (data) => {
      let dataCopy = { ...data };
      dataCopy.mobile = `(+${dataCopy.country})${dataCopy.mobile}`;
      dataCopy.date_of_birth = moment(dataCopy.date_of_birth).format('YYYY-MM-DD');
      delete dataCopy['country'];
      console.log('🚀 ~ onSubmitHandler ~ dataCopy:', dataCopy);

      await fetchData({ url: `patient/update`, loading: true, body: dataCopy }).then((result) => {
        if (result?.result?.message) {
          setDemographics(dataCopy);
          dispatch(setAlert({ txt: t('alert_box.demographics_submitted'), type: 'success' }));
          if (setRequestState) {
            setRequestState('service');
            setSearchParams({ id: result.result.patient_id || dataCopy['sub_profile'] || id });
          } else {
            setDemographics();
            setShowFields(false);
          }
        }
      });
    },
    [setRequestState, id]
  );

  return (
    <div className="d-flex flex-column gap-3">
      <Form
        customElement={
          <OnBehalfOf
            setBehalfOfFields={setBehalfOfFields}
            setShowFields={setShowFields}
            formValidate={formValidate}
            setDemographics={setDemographics}
            demographics={demographics}
            edit={!title}
          />
        }
        title={title && t('demographics.demographics')}
        inputArr={showFields ? inputArr : []}
        btnArr={showFields ? btnArr : []}
        selectArr={showFields ? selectArr : []}
        textAreaArr={showFields ? textAreaArr : []}
        inputWithDropdownArr={showFields ? inputWithDropdownArr : []}
        type="demographics"
        onSubmit={onSubmitHandler}
        setFormValidate={setFormvalidate}
        customField={behalfOfFields.validateFields}
        key="demographics"
      />
    </div>
  );
};

export default Demographics;
