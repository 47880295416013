import { useEffect, useState } from 'react';
import FileUpload from '../file handlers/File_Upload.jsx';
import FileCard from '../file handlers/File_Card.jsx';
import MainBtn from '../Button.jsx';
import { setAlert } from '../../features/main_functions.js';
import { useDispatch } from 'react-redux';
import useFetch from '../../hooks/useFetch.js';
import { useTranslation, Trans } from 'react-i18next';
import PatientNewCaseConsentPop from '../popups/patientNewCase/Patient_New_Case_Consent_pop.jsx';
import PatientNewCaseFilesGuidePop from '../popups/patientNewCase/Patient_New_Case_Files_Guide_Pop.jsx';
import { useNavigate, useParams } from 'react-router-dom';

const CaseFiles = ({ setRequestState, caseId, consentAccepted, setConsentAccepted }) => {
  const [physicianReport, setPhysicianReport] = useState([]);
  const [relevantFiles, setRelevantFiles] = useState([]);
  const [dicomFiles, setDicomFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});

  const { caseId: paramCaseId } = useParams();

  const case_id = caseId || paramCaseId;

  const dispatch = useDispatch();
  const { fetchData } = useFetch({});
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data } = useFetch({
    url: 'patient/medical/files/get/' + case_id,
    loading: true,
    body: {}
  });

  useEffect(() => {
    if (data) {
      if (
        (paramCaseId &&
          ['data collection' /* , 'assigned', 'under_review' */].includes(data.result.case_status)) ||
        !paramCaseId
      ) {
        data.result.patient_medical_files.forEach((e) => {
          e.uploaded = true;
          e.uploading = true;
          if (e.file_category == 'physician') {
            setPhysicianReport((file) => [...file, e]);
          } else if (e.file_category == 'dicom') {
            setDicomFiles((file) => [...file, e]);
          } else {
            setRelevantFiles((file) => [...file, e]);
          }
        });
      } else {
        paramCaseId ? navigate('../case/' + paramCaseId) : navigate('/');
      }
    }
  }, [data]);

  let upload = [
    {
      title: {
        txt: t('case_view.file_categories.treating physician/hospital reports'),
        desc: t('newCase.files.physician'),
        required: true
      },
      name: 'physician',
      accept: 'image/jpg,image/jpeg,image/png,application/pdf',
      setUpload: setPhysicianReport,
      uploadedFiles: physicianReport,
      maxFiles: 20,
      allowedTypes: 'Image, PDF'
    },
    {
      title: {
        txt: t('case_view.file_categories.relevant'),
        desc: t('newCase.files.relevant'),
        required: false
      },
      name: 'relevant',
      accept: 'image/jpg,image/jpeg,image/png,application/pdf,video/mp4,video/mov,video/mpeg,video/wmv',
      setUpload: setRelevantFiles,
      uploadedFiles: relevantFiles,
      maxFiles: 40,
      allowedTypes: 'Image, Video, PDF'
    },
    {
      title: {
        txt: t('case_view.file_categories.dicom'),
        desc: t('newCase.files.dicom'),
        required: false
      },
      name: 'dicom',
      accept: 'zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed',
      setUpload: setDicomFiles,
      uploadedFiles: dicomFiles,
      maxFiles: 10,
      allowedTypes: 'ZIP'
    }
  ];

  const removeFile = async (file, fileArr, setDisableFile) => {
    setDisableFile(true);
    const rmFun = () => {
      fileArr((e) => e.filter((o) => o.name != file.name));
      delete uploadProgress[file.name];
      setUploadProgress(uploadProgress);
      file.controller?.abort();
    };
    file.id
      ? await fetchData({ url: `patient/medical/files/delete/${file.id}`, loading: false, body: {} }).then(
          (res) => {
            if (res) {
              rmFun();
            } else {
              setDisableFile(false);
            }
          }
        )
      : rmFun();
  };

  const uploadFiles = async () => {
    const uploadFileApi = (file, type, controller) => {
      const formData = new FormData();
      formData.append('case_id', case_id);
      formData.append(type, file);
      return fetchData({
        url: 'patient/medical/files/upload',
        loading: false,
        body: { data: formData, name: file.name, signal: controller.signal },
        setUploadProgress,
        upload: true
      });
    };

    const uploadAndHandleFile = async (fileList, fileType, setFileList, category) => {
      const newFiles = [];
      const oldFiles = [];
      const uploadFilesArray = [];

      fileList.forEach((file) => {
        if (!file.uploading) {
          const controller = new AbortController();
          uploadFilesArray.push(() => uploadFileApi(file.file || file, fileType, controller));
          file.uploading = true;
          file.controller = controller;
          newFiles.push(file);
        } else {
          oldFiles.push(file);
        }
      });

      if (uploadFilesArray.length > 0) {
        setFileList([...oldFiles, ...newFiles]);
        uploadFilesArray.forEach(async (fileApi) => {
          await fileApi().then((result) => {
            result?.uploaded_files?.forEach((file) => {
              file.uploaded = true;
              file.uploading = true;
              if (fileList?.length > 0 && file.file_category == category) {
                setFileList((files) =>
                  files.map((currentFile) =>
                    currentFile.name == file.name && !currentFile.id ? file : currentFile
                  )
                );
              }
            });
          });
        });
      }
    };

    physicianReport?.length > 0 &&
      (await uploadAndHandleFile(physicianReport, 'physician_reports', setPhysicianReport, 'physician'));
    relevantFiles?.length > 0 &&
      (await uploadAndHandleFile(relevantFiles, 'relevant_files', setRelevantFiles, 'relevant'));
    dicomFiles?.length > 0 && (await uploadAndHandleFile(dicomFiles, 'dicom_files', setDicomFiles, 'dicom'));
  };

  useEffect(() => {
    uploadFiles();
  }, [relevantFiles, physicianReport, dicomFiles]);

  const handleSubmit = async () => {
    if (physicianReport.length == 0) {
      dispatch(setAlert({ txt: t('alert_box.case_files.please_upload'), type: 'warning' }));
    } else {
      let submitFiles = true;
      console.log(uploadProgress);
      Object.values(uploadProgress).forEach((e) => {
        console.log(!e);
        parseInt(e) >= 0 && (submitFiles = false);
      });
      if (submitFiles) {
        if (paramCaseId) {
          navigate('../case/' + paramCaseId);
        } else {
          await fetchData({
            url: `case/mark/uploaded/${case_id}`,
            loading: true,
            body: {
              params: {
                patient_flow: 'history'
              }
            }
          }).then((result) => {
            if (result) {
              dispatch(setAlert({ txt: t('alert_box.case_files.uploaded'), type: 'success' }));
              setRequestState('history');
            }
          });
        }
      } else {
        dispatch(setAlert({ txt: t('alert_box.case_files.please_wait'), type: 'warning' }));
      }
    }
  };

  const fileGuides = t('newCase.files.files_guide', { returnObjects: true });

  return consentAccepted || paramCaseId ? (
    <>
      <PatientNewCaseFilesGuidePop text={fileGuides} />
      <div className="d-flex justify-content-center">
        <div className="form-width">
          <p className="text-capitalize fw-semibold fs-4">{t('newCase.files.medical_files')}</p>
          <div className="mt-1">
            <div className="grey-txt ms-3 fs-7 fw-medium">
              {fileGuides.map((e, i) => (
                <p key={'guide' + i}>{e}</p>
              ))}
            </div>
          </div>
          {upload.map((e) => (
            <div key={e.title.txt}>
              <div className="mt-4">
                <span className="text-capitalize fw-bold fs-5">{e.title.txt}</span>
                {e.title.required && <span className="ms-1 text-danger fs-7">*{t('required')}</span>}
                <p className="ms-3 fs-8 mb-1 grey-txt">{e.title.desc}</p>
                <p className="ms-3 fs-8 mb-1 grey-txt">
                  {t('newCase.files.allowed_files')} <span className="fw-bold">[ {e.allowedTypes} ]</span>
                </p>
                <p className="ms-3 mb-3 fs-8 grey-txt">
                  <Trans t={t} i18nKey="newCase.files.max_files" values={{ val: e.maxFiles }} />
                </p>
              </div>
              <div className="mt-2 mb-3">
                <FileUpload
                  accept={e.accept}
                  allowedTypes={e.allowedTypes}
                  multiple={true}
                  setUpload={e.setUpload}
                  uploadedFiles={e.uploadedFiles}
                  title={'upload your ' + e.title.txt}
                  maxFiles={e.maxFiles}
                  attribute={e.name}
                  key={e.title.txt}
                />
              </div>
              <div className="row g-3 case-files-block">
                {e.uploadedFiles?.map((file, i) => (
                  <div className="col-sm-6 col-auto" key={file.name + i}>
                    <FileCard
                      file={file}
                      key={file.name + e.title.txt}
                      progress={uploadProgress}
                      removeFile={(_, setDisableFile) => removeFile(file, e.setUpload, setDisableFile)}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
          <div className="my-4 d-flex flex-column gap-3">
            <MainBtn
              txt={paramCaseId ? t('confirm') : t('next')}
              style="green-btn"
              type="button"
              action={handleSubmit}
              id="files-next"
            />
            {paramCaseId && (
              <MainBtn
                txt={t('back')}
                style="grey-btn"
                type="button"
                action={() => navigate('../case/' + paramCaseId)}
                id="files-back"
              />
            )}
          </div>
        </div>
      </div>
    </>
  ) : (
    <PatientNewCaseConsentPop setConsentAccepted={setConsentAccepted} caseId={case_id} />
  );
};

export default CaseFiles;
