import { useState } from 'react';
import Demographics from './demographics.jsx';
import Form from '../../components/Form.jsx';
import { useDispatch, useSelector } from 'react-redux';
import useFetch from '../../hooks/useFetch.js';
import { setAlert, setUserDetails } from '../../features/main_functions.js';
import { useTranslation } from 'react-i18next';

const PatientProfile = () => {
  const { firstName, lastName, email } = useSelector((state) => state.mainFun.user);

  const [tab, setTab] = useState('profile');

  const { fetchData } = useFetch({});
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const changeTab = (e) => {
    const classList = ['active-green', 'fw-semibold', 'green-txt'];
    if (e.target.id == 'profile') {
      setTab('profile');
      e.target.classList.add(...classList);
      document.getElementById('demographics').classList.remove(...classList);
    } else if (e.target.id == 'demographics') {
      setTab('demographics');
      e.target.classList.add(...classList);
      document.getElementById('profile').classList.remove(...classList);
    }
  };

  let inputArr = [
    {
      title: t('demographics.first_name'),
      placeholder: t('type', { val: 'demographics.first_name' }),
      name: 'first_name',
      colValue: 'col-6',
      type: 'text',
      value: firstName
    },
    {
      title: t('demographics.last_name'),
      placeholder: t('type', { val: 'demographics.last_name' }),
      name: 'last_name',
      colValue: 'col-6',
      type: 'text',
      value: lastName
    },
    {
      title: t('email'),
      placeholder: t('type', { val: 'onboarding.email' }),
      name: 'email',
      colValue: 'col-12',
      type: 'email',
      value: email,
      disabled: true
    }
  ];

  let btnArr = [
    {
      title: t('edit'),
      style: 'green-btn',
      type: 'submit'
    }
  ];

  const onSubmitHandler = async (data) => {
    console.log(data);
    let dataCopy = { ...data };
    delete dataCopy['email'];
    await fetchData({ url: `patient/update`, loading: true, body: dataCopy }).then((result) => {
      if (result?.result?.message) {
        dispatch(setAlert({ txt: t('alert_box.profile_updated'), type: 'success' }));
        dispatch(setUserDetails({ first_name: data.first_name, last_name: data.last_name }));
      }
    });
  };

  return (
    <div>
      <p className="fs-4 fw-semibold mb-4">{t('navbar.profile')}</p>
      <div className="d-flex gap-4 dark-grey-txt border-bottom mb-5">
        <p className="pb-1 pointer active-green fw-semibold green-txt" onClick={changeTab} id="profile">
          {t('demographics.personal_info')}
        </p>
        <p className="pb-1 pointer" onClick={changeTab} id="demographics">
          {t('demographics.demographics')}
        </p>
      </div>
      {tab == 'profile' ? (
        <Form
          onSubmit={onSubmitHandler}
          inputArr={inputArr}
          btnArr={btnArr}
          type="profile"
          key="profileInfo"
        />
      ) : (
        tab == 'demographics' && <Demographics title={false} />
      )}
    </div>
  );
};

export default PatientProfile;
