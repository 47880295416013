import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Register from './register.jsx';
import CaseList from '../../pages/common/case_list.jsx';
import Login from '../common/login.jsx';
import ActivateAccount from './activate_account.jsx';
import ChangePassword from '../common/change_password.jsx';
import ForgetPassword from '../common/forgot_password';
import ResetPassword from '../common/reset_passowrd.jsx';
import PatientProfile from './profile.jsx';
import { useDispatch, useSelector } from 'react-redux';
import TermsOfUse from '../common/terms_of_use.jsx';
import PrivacyPolicy from '../common/privacy_policy.jsx';
import { lazy, Suspense, useEffect, useState } from 'react';
import { setUserDetails } from '../../features/main_functions.js';
import CaseFiles from '../../components/newCase/Case_Files.jsx';

function PatientRoutes() {
  const CaseView = lazy(() => import('./case_view.jsx'));
  const NewCase = lazy(() => import('./new_case.jsx'));

  const role = useSelector((state) => state.mainFun.user.role);
  const portalToken = import.meta.env.VITE_PATIENT_PORTAL;
  const [queuedUrl, setQueuedUrl] = useState();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  //check's if there is token then user is logged in then he can acces this url, else he is navigated to login page
  const HandleRoutes = ({ element }) => {
    if (!localStorage.token) {
      setQueuedUrl(pathname);
      return <Navigate to="/patient/login" />;
    } else if (localStorage.token && role === 'patient' && element) {
      if (queuedUrl) {
        setQueuedUrl();
        return <Navigate to={queuedUrl} />;
      } else {
        return element;
      }
    } else if (localStorage.token && !element) {
      return <Navigate to="/patient/home" />;
    } else if (role !== 'patient') {
      return <Navigate to={`/${role}/home`} />;
    } else {
      return null;
    }
  };

  const HandleBourdingRoutes = ({ element }) =>
    localStorage.token ? <Navigate to="/patient/home" /> : element;

  useEffect(() => {
    if (role != 'patient' && !localStorage.token) {
      dispatch(setUserDetails({ user_type: 'patient' }));
    }
  }, []);

  return (
    <Routes>
      <Route path="/" element={<HandleRoutes />} />
      <Route
        path="/signup"
        element={<HandleBourdingRoutes element={<Register portalToken={portalToken} />} />}
      />
      <Route
        path="/login"
        element={<HandleBourdingRoutes element={<Login portalToken={portalToken} role="patient" />} />}
      />
      <Route
        path="/forgotPassword"
        element={<HandleBourdingRoutes element={<ForgetPassword portalToken={portalToken} />} />}
      />
      <Route path="/password/reset" element={<HandleBourdingRoutes element={<ResetPassword />} />} />
      <Route path="/activate" element={<ActivateAccount portalToken={portalToken} />} />
      <Route path="/changePassword" element={<HandleRoutes element={<ChangePassword />} />} />
      <Route path="/home" element={<HandleRoutes element={<CaseList />} />} />
      <Route
        path="/newCase"
        element={
          <HandleRoutes
            element={
              <Suspense>
                <NewCase />
              </Suspense>
            }
          />
        }
      />
      <Route
        path="/case/:caseId"
        element={
          <HandleRoutes
            element={
              <Suspense>
                <CaseView />
              </Suspense>
            }
          />
        }
      />
      <Route path="/case/:caseId/uploadFiles" element={<HandleRoutes element={<CaseFiles />} />} />
      <Route
        path="/case/view/:caseId"
        element={
          <Suspense>
            <CaseView />
          </Suspense>
        }
      />
      <Route path="/profile" element={<HandleRoutes element={<PatientProfile />} />} />
      <Route path="/privacyPolicy" element={<PrivacyPolicy portalToken={portalToken} />} />
      <Route path="/termsOfUse" element={<TermsOfUse portalToken={portalToken} />} />
      <Route path="*" element={<Navigate to="/notfound" />} />
    </Routes>
  );
}

export default PatientRoutes;
