import { useDispatch } from 'react-redux';
import cloud from '../../assets/images/svg/cloud.svg';
import { useEffect, useRef } from 'react';
import { setAlert } from '../../features/main_functions.js';
import { useTranslation } from 'react-i18next';

function FileUpload({
  accept,
  multiple,
  setUpload,
  uploadedFiles,
  uploadProgress,
  name,
  maxFiles,
  attribute,
  allowedTypes
}) {
  const uploadRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const fileTypeCheck = (file) => accept.split(',').includes(file.type);

  const fileCheck = (upFiles, addNewFile) => {
    Array.from(upFiles).forEach((file) => {
      if (fileTypeCheck(file)) {
        let pushFile = false;
        if (uploadedFiles.length > 0) {
          let foundFile = uploadedFiles.some((e) => e.name == file.name);
          !foundFile && (pushFile = true);
        } else {
          pushFile = true;
        }
        if (pushFile) {
          addNewFile(file);
        } else {
          dispatch(setAlert({ txt: t('alert_box.file_same_name'), type: 'warning' }));
        }
      } else {
        dispatch(setAlert({ txt: t('alert_box.wrong_file_type', { types: allowedTypes }), type: 'danger' }));
      }
    });
  };

  const multiUpload = (upFiles) => {
    //uploading multiple files
    if (upFiles.length + uploadedFiles?.length <= maxFiles || !maxFiles) {
      let newFiles = [];
      const addNewFile = (file) => {
        //add the file if is not already exists
        file.mimetype = file.type;
        file.uploaded = true;
        name && (file.id = name);
        newFiles.push(file);
      };
      fileCheck(upFiles, addNewFile); //check if file is not already exists then add it
      newFiles.length > 0 && setUpload((e) => [...e, ...newFiles]);
    } else {
      dispatch(setAlert({ txt: t('alert_box.max_file_length', { val: maxFiles }), type: 'warning' }));
    }
  };

  const singleUpload = (upFile) => {
    //uploading single file
    upFile.mimetype = upFile.type;
    upFile.uploaded = true;
    upFile.id = name;
    setUpload([upFile]);
  };

  useEffect(() => {
    uploadRef.current?.firstChild?.setAttribute('accept', accept);
    name && uploadRef.current?.firstChild?.setAttribute('name', name);
  }, []);

  /*   useEffect(() => {
    uploadRef.current.firstChild.setAttribute('webkitdirectory', 'webkitdirectory');
    uploadRef.current.firstChild.setAttribute('mozdirectory', 'mozdirectory');
  }, []); */

  useEffect(() => {
    //update the input tag file values when removing file from file card
    let dt = new DataTransfer();
    let input = uploadRef.current?.firstChild;
    if (uploadedFiles?.length > 0) {
      for (let i = 0; i < uploadedFiles.length; i++) {
        uploadedFiles[i].uploaded && uploadedFiles[i].file && dt.items.add(uploadedFiles[i].file);
      }
    }
    input && (input.files = dt.files);
  }, [uploadedFiles]);

  const dropHandler = (ev) => {
    onDragLeave();
    ev.preventDefault();
    if (!uploadProgress) {
      if (ev.dataTransfer.files.length > 0) {
        if (multiple) {
          multiUpload(ev.dataTransfer.files);
        } else {
          if (ev.dataTransfer.files.length == 1) {
            singleUpload(ev.dataTransfer.files[0]);
          } else {
            dispatch(setAlert({ txt: t('alert_box.one_file_only'), type: 'warning' }));
          }
        }
      }
    }
  };

  const dragOverHandler = (ev) => {
    ev.preventDefault();
    uploadRef.current.classList.add('opacity-25');
  };

  const onDragLeave = () => uploadRef.current.classList.remove('opacity-25');

  return (
    <div
      className={`d-flex flex-column align-items-center justify-content-center p-4 mainInput bg-grey ${
        !uploadProgress ? 'pointer' : ''
      } h-100`}
      onClick={() => !uploadProgress && uploadRef.current.firstChild.click()}
      onDrop={dropHandler}
      onDragOver={dragOverHandler}
      onDragLeave={onDragLeave}
      ref={uploadRef}
      test-id={attribute}
    >
      {uploadProgress ? (
        <div className="d-flex flex-column align-items-center">
          <div className="spinner-border mb-3" role="status">
            <span className="sr-only" />
          </div>
          <span>uploading ({uploadProgress[name]}%) ...</span>
        </div>
      ) : (
        <>
          <input
            className="d-none"
            type="file"
            multiple={multiple}
            name="files"
            onChange={(e) => (multiple ? multiUpload(e.target.files) : singleUpload(e.target.files[0]))}
          />
          <img className="cloud-ico" src={cloud} />
          <p className="text-capitalize green-txt text-center">
            {/* {title} */}
            {t('drag_drop')}
          </p>
        </>
      )}
    </div>
  );
}

export default FileUpload;
