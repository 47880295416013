import PopupContainer from '../../Popup_Container.jsx';

function PatientNewCaseFilesGuidePop({ text }) {
  return (
    <PopupContainer open={true} type="warning">
      <div>
        <ul className="ms-3 fs-7 fw-medium p-0">
          {text.map((e) => (
            <li>{e}</li>
          ))}
        </ul>
      </div>
    </PopupContainer>
  );
}
export default PatientNewCaseFilesGuidePop;
