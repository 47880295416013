import Form from '../../components/Form.jsx';
import { createElement, useCallback, useLayoutEffect, useState } from 'react';
import useFetch from '../../hooks/useFetch.js';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../features/main_functions.js';

function Register({ portalToken }) {
  const [displayedEmail, setDisplayedEmail] = useState('');
  const [displayCaptcha, setDisplayCaptcha] = useState(false);
  const [checkBoxes, setCheckBoxes] = useState();
  const [customFields, setCustomFields] = useState();
  const [apiError, setApiError] = useState();

  const { fetchData } = useFetch({});
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let inputArr = [
    {
      title: t('demographics.first_name'),
      placeholder: t('type', { val: 'demographics.first_name' }),
      name: 'first_name',
      colValue: 'col-6'
    },
    {
      title: t('demographics.last_name'),
      placeholder: t('type', { val: 'demographics.last_name' }),
      name: 'last_name',
      colValue: 'col-6'
    },
    {
      title: t('onboarding.email'),
      placeholder: t('type', { val: 'onboarding.email' }),
      name: 'email'
    },
    {
      title: t('onboarding.confirm_email'),
      placeholder: t('type', { val: 'onboarding.email' }),
      name: 'confirm email'
    },
    {
      title: t('onboarding.password'),
      placeholder: t('type', { val: 'onboarding.password' }),
      type: 'password',
      name: 'password'
    },
    {
      title: t('onboarding.confirm_password'),
      placeholder: t('type', { val: 'onboarding.password' }),
      type: 'password',
      name: 'confirm password'
    }
  ];

  let checkboxArr = [
    {
      title: createElement(
        'p',
        {},
        t('onboarding.by_creating.0') + ' ',
        createElement(
          Link,
          { className: 'green-txt', to: '../termsofuse', target: '_blank' },
          t('terms_use')
        ),
        ' ' + t('onboarding.by_creating.1') + ' ',
        createElement(
          Link,
          { className: 'green-txt', to: '../privacypolicy', target: '_blank' },
          t('privacy_policy')
        )
      ),
      name: 'policyaccept'
    }
  ];

  let btnArr = [
    {
      title: t('onboarding.create_account'),
      style: 'green-btn',
      type: 'submit'
    }
  ];

  useLayoutEffect(() => {
    fetchData({
      url: 'registration/settings/get',
      loading: true,
      body: { params: { 'Portal-Token': portalToken } }
    }).then((result) => {
      if (result) {
        if (result.result?.recaptcha) setDisplayCaptcha(true);
        if (result.result.registration_settings) {
          let customFieldsArr = [];
          Object.values(result.result.registration_settings).forEach((e) => {
            if (e) {
              checkboxArr.push({
                title: e.name,
                name: e.key
              });
              customFieldsArr.push({
                name: e.key,
                validate: e.is_required ? 'checkBox_required' : 'checkBox_notRequired'
              });
            }
          });
          setCustomFields(customFieldsArr);
        }
        setCheckBoxes(checkboxArr);
      }
    });
  }, []);

  const onSubmitHandler = useCallback(
    async (data) => {
      let dataCopy = { ...data };
      delete dataCopy['policyaccept'];
      delete dataCopy['confirm password'];
      delete dataCopy['confirm email'];
      dataCopy['registration_settings'] = {
        residence: dataCopy.residence,
        newsletters_updates: dataCopy.newsletters_updates
      };
      dataCopy['Portal-Token'] = portalToken;
      dataCopy.email = dataCopy.email.toLowerCase();
      console.log(dataCopy);
      await fetchData({ url: 'account/register', loading: true, body: dataCopy, returnError: true }).then(
        (result) => {
          if (result?.result?.message) {
            setDisplayedEmail(dataCopy.email);
          } else if (result.status == 409) {
            setApiError({ name: 'email', message: t('onboarding.email_in_use') });
          }
        }
      );
    },
    [portalToken]
  );

  const resendActivation = async () =>
    await fetchData({
      url: 'account/activate/resend',
      loading: true,
      body: { email: displayedEmail, 'Portal-Token': portalToken }
    }).then((result) => {
      if (result) dispatch(setAlert({ txt: t('alert_box.email_sent'), type: 'success' }));
    });

  return displayedEmail ? (
    <div className="d-flex justify-content-center">
      <div className="d-flex flex-column gap-3 text-start">
        <h2>
          {t('onboarding.check_inbox.0')}
          <br />
          {t('onboarding.check_inbox.1')}
        </h2>
        <p>
          {t('onboarding.check_inbox.2') + ' '}
          <span className="fw-bold">{displayedEmail}</span>
        </p>
        <p>
          {t('onboarding.did_not_get') + ' '}
          <span className="fw-bold pointer green-txt" onClick={resendActivation}>
            {t('onboarding.send_new_email')}
          </span>
        </p>
        <p>
          {t('onboarding.check_inbox.3') + ' '}
          <Link to="../login" className="fw-bold pointer green-txt">
            {t('login')}
          </Link>
        </p>
      </div>
    </div>
  ) : (
    checkBoxes && (
      <Form
        title={t('onboarding.register')}
        inputArr={inputArr}
        customElement={
          <p className="fs-7 fw-medium alert alert-info py-3 px-2 rounded-3 border-0 m-0">
            Please provide <strong>your contact information</strong>. We will ask for patient's details later,
            whether you are seeking a second medical opinion for yourself or for someone else.
          </p>
        }
        btnArr={btnArr}
        checkboxArr={checkBoxes}
        type="registerPatient"
        captcha={displayCaptcha}
        onSubmit={onSubmitHandler}
        customField={customFields}
        apiError={apiError}
        key="registerPatient"
      />
    )
  );
}
export default Register;
