import pdf from '../../assets/images/svg/pdf-icon.svg';
import mp4 from '../../assets/images/svg/mp4.svg';
import jpg from '../../assets/images/svg/jpg.svg';
import other from '../../assets/images/svg/other.svg';
import { useEffect, useLayoutEffect, useState } from 'react';
import FileView from './File_Popup.jsx';
import moment from 'moment';
import eye from '../../assets/images/svg/eye.svg';
import trash from '../../assets/images/svg/trash.svg';

function FileCard({ file, removeFile, progress }) {
  const [fileType, setFileType] = useState('');
  const [fileVector, setFileVector] = useState('');
  const [showFile, setShowFile] = useState(false);
  const [disableFile, setDisableFile] = useState(false);
  console.log(file);

  const fileViewConfig = (type, vector, navigating) => {
    if (navigating) {
      return type;
    } else {
      setFileType(type);
      setFileVector(vector);
      return null;
    }
  };

  const fileTypes = {
    video: (navigating) => fileViewConfig('video', mp4, navigating),
    image: (navigating) => fileViewConfig('img', jpg, navigating),
    pdf: (navigating) => fileViewConfig('pdf', pdf, navigating),
    dicom: (navigating) => fileViewConfig('dicom', other, navigating),
    other: (navigating) => fileViewConfig('other', other, navigating)
  };

  const getFileType = (targetFile, navigating) => {
    if (targetFile?.type1 && targetFile?.type1[1] == 'DICOM') {
      return fileTypes['dicom'](navigating);
    } else if (targetFile?.mimetype) {
      const findType = Object.entries(fileTypes).find(([key, value]) => targetFile.mimetype.includes(key));
      if (findType) {
        return findType[1](navigating);
      } else {
        return fileTypes['other'](navigating);
      }
    } else {
      return fileTypes['other'](navigating);
    }
  };

  const fileViewed = () => {
    setShowFile(true);
  };

  useLayoutEffect(() => {
    getFileType(file);
  }, []);

  useEffect(() => {
    if (progress?.[file.name] >= 97 && !disableFile) {
      setDisableFile(true);
    } else if (!progress?.[file.name] && disableFile) {
      setDisableFile(false);
    }
  }, [progress]);
  console.log(progress);

  return (
    <div
      className="card rounded-3 d-flex flex-column gap-2 pdf-card h-100 position-relative"
      id={'fileCard-' + file.id}
    >
      {disableFile && (
        <div className="position-absolute top-0 start-0 w-100 h-100 bg-body-secondary bg-opacity-75 rounded-3 d-flex align-items-center justify-content-center z-1 fw-bold text-black">
          {progress?.[file.name] ? 'Finishing...' : 'Removing...'}
        </div>
      )}
      {showFile && (
        <FileView
          file={file}
          showFile={showFile}
          getFileType={getFileType}
          setShowFile={setShowFile}
          type={fileType}
          removeFile={removeFile}
        />
      )}
      <div className="d-flex gap-3 align-items-center pointer">
        <div className="d-flex gap-2 align-items-center" onClick={fileViewed}>
          <div className="d-flex justify-content-center">
            <img height={50} src={fileVector} loading="lazy" />
          </div>

          <div className="fw-medium fs-8">
            <p className="text-break">{file?.name || 'no name'}</p>
            <p className="grey-txt mt-1">{moment(file?.create_date).format('ll')}</p>
          </div>
          <img className="icon-color" src={eye} />
        </div>
        {(file.isDeleted || file.uploaded) && (
          <img src={trash} onClick={() => removeFile(file, setDisableFile)} />
        )}
      </div>
      {progress?.[file.name] && progress[file.name] >= 0 && progress[file.name] < 100 && (
        <div
          className="progress mt-auto"
          role="progressbar"
          aria-label="Basic example"
          aria-valuenow={progress[file.name]}
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div className="progress-bar" style={{ width: `${progress[file.name]}%` }}></div>
        </div>
      )}
    </div>
  );
}

export default FileCard;
